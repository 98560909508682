import React, { useContext } from "react";
import { GlobalDataContext } from "../context/context";
import Directories from "../components/Home/Directories";
import FormHome from "../components/Home/FormHome";
import Map from "../components/Contact/MapContent";
import Modal from "../components/Home/Modal";
import BaseLayout from "../components/global/BaseLayout";
import CounterUp from "../components/global/CounterUp";
import ServicesHome from "../components/Services/ServicesHome_3";
import ContentServices from "../components/Services/ContentServices";
import HeroVideo from "../components/Home/HeroSection";
import Paletacolor from "../components/global/Paletacolor";
import BlockFour from "../components/block/BlockFour";
import BlockThree from "../components/block/BlockThree";
import BlockOne from "../components/block/BlockOne";
import VideoPromocional from "../components/global/VideoPromocional";
import GalleryComponent from "../components/Gallery/Gallery";

// para agregar la frase de los años de experiencia o la licencia agregar sloganPrincipal={true}
// para agregar la lista de about agregar listsAbout={true}
// para agregar la lista de servicios agregar listsServices={true}

function Home() {
  const { rpdata } = useContext(GlobalDataContext);
  return (
    <BaseLayout PageName="Home">
      <div className="md:max-w-full w-full">
        <Modal />

        <HeroVideo />

        <BlockThree
          text={rpdata?.dbHome?.[0].text}
          image={rpdata?.stock?.[10]}
          sloganPrincipal={true}
          listsServices={true}
        />

        {/* video promocional */}
        {rpdata?.videosPromo?.[0]?.activo ? (
          <VideoPromocional
            title={rpdata?.dbSlogan?.[2]?.slogan}
            text={rpdata?.dbAbout?.[1]?.text}
            linkVideo={`${rpdata?.videosPromo?.[0].link}`}
            image={`${rpdata?.videosPromo?.[0].img}`}
          />
        ) : null}

        <Directories />

        <BlockFour
          title={rpdata?.dbSlogan?.[3]?.slogan}
          text={rpdata?.dbHome?.[1]?.text}
          image1={rpdata?.stock?.[7]}
          image2={rpdata?.stock?.[8]}
          image3={rpdata?.stock?.[9]}
        />

        <CounterUp image={rpdata?.gallery?.[32]} />

        <BlockOne
          title={"a little about us"}
          text={rpdata?.dbAbout?.[0]?.text}
          image={rpdata?.stock?.[3]}
          listsAbout={true}
        />

        {/* our reviews */}
        {rpdata?.reviews?.isHomeOnly === true ? (
          <div className="w-4/5 mx-auto py-[100px]">
            <h1 className="pb-10 text-center"> Our Reviews</h1>
            <div className={`${rpdata?.reviews?.links?.[0]}`}></div>
          </div>
        ) : null}

        {/* <ServicesHome /> */}
        <BlockOne
          title={rpdata?.dbSlogan?.[2]?.slogan}
          text={rpdata?.dbAbout?.[1]?.text}
          image={rpdata?.stock?.[5]}
        />

        <ContentServices />

        {/* Paleta de Colores */}

        {rpdata?.brandingExtra?.[0]?.activo ? <Paletacolor /> : null}

        <GalleryComponent />

        <div
          className="bgFormHome"
          style={{ backgroundImage: `url("${rpdata?.stock?.[6]}")` }}
        >
          <div className="relative">
            <FormHome />
          </div>
        </div>

        <Map />
      </div>
    </BaseLayout>
  );
}

export default Home;
